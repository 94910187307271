<template>
    <div class="h-full w-full flex flex-col items-center pb-14 px-14 overflow-auto">
        <div class="space-y-3 flex flex-col items-center flex-shrink-0">
            <p class="text-4.5xl leading-5xl font-benton-light text-center flex-shrink-0">THE</p>
            <h1 class="text-7.45xl leading-6.5xl font-didot text-center flex-shrink-0">{{activeCollection.name}}</h1>
            <p class="text-4.5xl leading-5xl font-benton-light text-center flex-shrink-0">COLLECTION</p>
        </div>

        <div class="mt-10 px-20 flex-shrink-0" v-if="activeCollection.description.length">
            <p v-for="(line, index) in activeCollection.description"
               :key="index"
               class="font-benton-regular text-2.5xl leading-4xl text-center"
            >
                {{line}}
            </p>
        </div>

        <div class="grid grid-cols-2 w-full font-benton mt-10 flex-shrink-0">
            <div class="slide-in flex-shrink-0"
                 v-for="(product, index) in products"
                 :key="product.name"
                 :style="{'animation-delay': (1+index)*0.1+'s'}"
            >
                <router-link class="flex flex-col items-center uppercase"
                             :to="
                         {
                             name: 'product',
                             params: {
                                 product: product.slug,
                                 collection: activeCollection.slug
                             }
                         }"
                >
                    <div class="w-full px-15 flex items-center justify-center flex-shrink-0">
                        <img class="w-80" :src="product.images.bra" />
                    </div>
                    <div class="flex flex-col items-center justify-center font-benton px-15 flex-shrink-0">
                        <h2 class="text-3.5xl text-center font-benton-medium">{{product.name}}</h2>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="w-full px-14 mt-auto flex-shrink-0">
            <div class="border-t-2 border-navborder flex items-center justify-center text-2.5xl leading-4xl pt-11 flex-shrink-0">
                <p>Need some assistance? <span class="font-bold">Ask an associate for help.</span></p>
            </div>
        </div>
    </div>
</template>

<script>
import Products from "@/router/products";
import Collections from "@/router/collections";
import _ from 'lodash';

export default {
    name: "GenericProductsPage",
    computed: {
        activeCollection() {
            return _.find(Collections, (_collection) => {
                return _collection.slug === this.collection;
            });
        },

        products() {
            return _.filter(Products, (_product) => {
                return _.findIndex(_product.collections, (_collection) => {
                    return _collection.slug === this.collection;
                }) !== -1;
            });
        },

        tagline() {
            return this.activeCollection ? (this.activeCollection.description || null) : null;
        },
    },

    props: {
        collection: {
            type: String,
            required: true,
        },
    }
}
</script>

<template>
<div class="h-full w-full flex flex-col">
    <transition name="scale" appear>
<!--        <video class="absolute top-0 left-0 flex-shrink-0 pointer-events-none"-->
<!--               src="/videos/vs_opener_bras.mp4"-->
<!--               preload="auto"-->
<!--               muted-->
<!--               autoplay-->
<!--               loop-->
<!--               playsinline-->
<!--               v-if="true"-->
<!--               poster="/images/video_poster.jpg"-->
<!--        />-->
        <img class="absolute top-0 left-0 flex-shrink-0 pointer-events-none"
             src="https://vs-bras-101.s3.eu-west-2.amazonaws.com/video_poster.jpg"
             v-if="true"
        />
    </transition>
    <transition name="slide-up-entrance" appear>
        <div class="flex-shrink-0 z-10 mt-0 mt-auto bg-white px-9 py-11 mb-40" v-if="true">
            <div class="text-center flex flex-col items-center justify-cente">
                <p class="font-didot text-6.5xl leading-6.5xl mb-6">
                    FIND THE BRA THAT'S<br>RIGHT FOR YOU
                </p>

                <router-link class="relative rounded-full w-40 h-40"
                             :to="{name: 'home'}"
                >
                    <button class="relative rounded-full bg-black text-white font-didot w-40 h-40 text-3.5xl leading-9.5 z-4">
                        START
                    </button>
                    <span class="left-0 top-0 animate-ping absolute inline-flex h-full w-full rounded-full bg-black opacity-75 -z-1"></span>
                </router-link>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
export default {
    name: "VideoPage"
}
</script>

<style scoped>
.slide-up-entrance-enter-active {
    transition: all 500ms cubic-bezier(0.155, 0.320, 0.560, 1.085);
    z-index: 50;
    transition-delay: 0.4s;
}

.slide-up-entrance-leave-active {
    transition: all 500ms 500ms cubic-bezier(0.155, 0.320, 0.560, 1.085);
}

.slide-up-entrance-leave-to {
    opacity: 0;
}

.slide-up-entrance-enter-from {
    transform: translateY(140%);
}
</style>
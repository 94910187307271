export default [
    {
        name: 'XS - XL',
        slug: 'xs_to_xl',
        thumb: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/xs_xl_thumb.jpg',
        poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/xs_xl_poster.jpg',
        categories: [
            'shop_by_size',
        ],
        cupSizes: [
            'XS',
            'S',
            'SDD',
            'M',
            'MDD',
            'L',
            'LDD',
            'XL'
        ],
        breadcrumb: {
            label: 'XS - XL',
        },
    },
    {
            name: '32',
            slug: '32',
            thumb: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/32_thumb.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/32_poster.jpg',
            categories: [
                'shop_by_size',
        ],
            cupSizes: [
                '32A',
                '32B',
                '32C',
                '32D',
                '32DD (E)',
                '32DDD (F)'
        ],
            breadcrumb: {
            label: '32',
        },
    },
    {
        name: '34',
        slug: '34',
        thumb: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/34_thumb.jpg',
        poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/34_poster.jpg',
        categories: [
            'shop_by_size',
        ],
        cupSizes: [
            '34A',
            '34B',
            '34C',
            '34D',
            '34DD (E)',
            '34DDD (F)'
        ],
        breadcrumb: {
            label: '34',
        },
    },
    {
        name: '36',
        slug: '36',
        thumb: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/36_thumb.jpg',
        poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/36_poster.jpg',
        categories: [
            'shop_by_size',
        ],
        cupSizes: [
            '36B',
            '36C',
            '36D',
            '36DD (E)',
            '36DDD (F)'
        ],
        breadcrumb: {
            label: '36',
        },
    },
    {
        name: '38',
        slug: '38',
        thumb: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/38_thumb.jpg',
        poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/sizes/38_poster.jpg',
        categories: [
            'shop_by_size',
        ],
        cupSizes: [
            '38B',
            '38C',
            '38D',
            '38DD (E)',
            '38DDD (F)'
        ],
        breadcrumb: {
            label: '38',
        },
    }
];
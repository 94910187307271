export default [


    // very sexy
    {
        name: 'Bombshell',
        slug: 'vsxy_bombshell',
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 2,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell/poster.jpg',
        },
        spec: [
            'Add-2-cup push-up',
            'Plush padding',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '36B',
                    '36C',
                    '36D',
                    '38C',
                ]
            ],
            abbreviated: [
                [
                    '32A-D',
                    '34A-D',
                    '36B-D',
                    '38C',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '32AA',
                    '34AA',
                    '34DD',
                    '36AA-A',
                    '36DD',
                    '38D-38DD',
                ]
            ]
        }
    },
    {
        name: 'Push-Up',
        slug: 'vsxy_push_up',
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 1,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 1,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/vs_push_up/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/vs_push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/vs_push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/vs_push_up/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Add-1-cup push-up',
            'Plush padding',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DDD',
                    '34B-DDD',
                    '36B-DDD',
                    '38B-DD',
                ]
            ],
            selectStores: [
                [
                    '32A',
                    '34A',
                    '38DDD',
                ]
            ],
            online: [
                [
                    '30B-D',
                    '36A',
                    '40C-40DD',
                ]
            ]
        }
    },
    // {
    //     name: 'Love',
    //     alternativeName: [
    //         'PUSH-UP',
    //     ],
    //     slug: 'vsxy_love',
    //     collections: [
    //         {
    //             slug: 'very_sexy',
    //             label: 'LOVE BY VICTORIA',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'push_up',
    //             label: 'PUSH-UP',
    //             order: 3,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/love/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/love/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/love/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/love/poster.jpg',
    //     },
    //     spec: [
    //         'Natural uplift',
    //         'Cushioned underwire',
    //         'Elongated cup',
    //         'Ballet-inspired back',
    //     ],
    //     sizes: {
    //     primary: [
    //     [
    //         'XS',
    //         'S',
    //         'SDD',
    //         'M',
    //         'MDD',
    //         'L',
    //         'LDD',
    //         'XL',
    //         '32A',
    //         '32B',
    //         '32C',
    //         '32D',
    //         '32DD (E)',
    //         '32DDD (F)',
    //         '34A',
    //         '34B',
    //         '34C',
    //         '34D',
    //         '34DD (E)',
    //         '34DDD (F)',
    //         '36B',
    //         '36C',
    //         '36D',
    //         '36DD (E)',
    //         '36DDD (F)',
    //         '38B',
    //         '38C',
    //         '38D',
    //         '38DD (E)',
    //         '38DDD (F)',
    //     ]
    // ],
    //         abbreviated: [
    //             [
    //                 '32B-DDD',
    //                 '34B-DDD',
    //                 '36B-DDD',
    //                 '38B-DD',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [
    //             [
    //                 '32-34A',
    //                 '38DDD',
    //             ]
    //         ]
    //     }
    // },
    {
        name: 'Strapless',
        slug: 'vsxy_strapless',
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 1,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/strapless/poster.jpg',
        },
        spec: [
            'Plunge Neckline',
            'Plush Push-Up Padding',
            'Patented Non-Slip Band',
            'Removable Straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '38C',
                    '38D',
                ]
            ],
            abbreviated: [
                [
                    '32A-DD',
                    '34A-DD',
                    '36B-DD',
                    '38C-D',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '36A',
                    '38B',
                    '38DD',
                ]
            ],
        }
    },
    {
        name: 'So Obsessed',
        alternativeName: [
            'PUSH-UP'
        ],
        slug: 'vsxy_so_obsessed',
        collections: [
            {
                slug: 'very_sexy',
                label: 'SO OBSESSED',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 3,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed/poster.jpg',
        },
        spec: [
            'Foam-encased underwire cups',
            'Adds 1 \u00BD cups',
            'Smoothing sides for a seamless look',
            'Fully adjustable straps',
            'VS logo charm in front'
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '38C',
                    '38D',
                ]
            ],
            abbreviated: [
                [
                    '32A-D',
                    '34A-DD',
                    '36B-DD',
                    '38C-D',
                ]
            ],
            selectStores: []
            ,
            online: [
                [
                    '32AA',
                    '34AA',
                    '36AA-A',
                    '38DD'
                ]
            ]
        }
    },

    // dream angels
    {
        name: 'Demi',
        slug: 'da_demi',
        collections: [
            {
                slug: 'dream_angels',
                label: 'DREAM ANGELS',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 1,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 1,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 3,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/demi/poster.jpg',
        },
        spec: [
            'Lightly lined pad',
            'Memory fit lining',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [
                [
                    '32-34A',
                    '38B'
                ]
            ],
            online: [
                [
                    '30B-D',
                    '36A',
                    '38G',
                    '40C-DDD',
                    '42D-DD',
                    '44C-D',
                ]
            ],
        }
    },
    {
        name: 'Push-Up',
        slug: 'da_push_up',
        collections: [
            {
                slug: 'dream_angels',
                label: 'DREAM ANGELS',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 2,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 4,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/push_up/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/push_up/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Memory fit push-up pad',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],

            abbreviated: [
                [
                    '32-34A-DDD',
                    '36-38B-DDD',
                ]
            ],
            selectStores: [
                [
                    '32-34DDD',
                    '36DD-DDD',
                    '38B',
                    '38DD-DDD',
                ]
            ],
            online: [
                [
                    '36A',
                    '40C-DDD',
                ]
            ],
        }
    },
    {
        name: 'Wicked',
        alternativeName: [
            'Unlined',
            'Balconette',
        ],
        slug: 'da_wicked',
        collections: [
            {
                slug: 'dream_angels',
                label: 'DREAM ANGELS WICKED',
                isPrimary: true,
            },
            {
                slug: 'unlined',
                label: 'UNLINED',
                order: 1,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 5,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wicked/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wicked/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wicked/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wicked/poster.jpg',
        },
        spec: [
            'Unlined',
            'Push-up without padding',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [
                [
                    '32-34A',
                    '38B',
                ]
            ],
            online: [
                [
                    '30B-D',
                    '38G',
                    '40DD-DDD,',
                ],
                [
                    '42D-DD',
                    '44C-D',
                ]
            ],
        }
    },
    // {
    //     name: 'Wireless',
    //     slug: 'da_wireless',
    //     collections: [
    //         {
    //             slug: 'dream_angels',
    //             label: 'DREAM ANGELS',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'wireless',
    //             label: 'WIRELESS',
    //             order: 1,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wireless/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wireless/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wireless/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wireless/poster.jpg',
    //     },
    //     spec: [
    //         'Soft lace cups and wings',
    //         'No wires (really!)',
    //         'Supportive, plunging silhouette',
    //     ],
    //     sizes: {
    //         primary: [
    //         [
    //             'XS',
    //             'S',
    //             'SDD',
    //             'M',
    //             'MDD',
    //             'L',
    //             'LDD',
    //             'XL',
    //             '32A',
    //             '32B',
    //             '32C',
    //             '32D',
    //             '32DD (E)',
    //             '32DDD (F)',
    //             '34A',
    //             '34B',
    //             '34C',
    //             '34D',
    //             '34DD (E)',
    //             '34DDD (F)',
    //             '36B',
    //             '36C',
    //             '36D',
    //             '36DD (E)',
    //             '36DDD (F)',
    //             '38B',
    //             '38C',
    //             '38D',
    //             '38DD (E)',
    //             '38DDD (F)',
    //         ]
    //     ],
    //         abbreviated: [
    //             [
    //                 '32C-DD',
    //                 '34B-DD',
    //                 '36B-DD',
    //                 '38B-DD',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [
    //             [
    //                 '32A',
    //                 '32B',
    //                 '34A',
    //                 '40C-D',
    //             ]
    //         ]
    //     }
    // },
    {
        name: 'Strapless',
        slug: 'da_strapless',
        collections: [
            {
                slug: 'dream_angels',
                label: 'DREAM ANGELS',
                isPrimary: true,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 2,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/strapless/poster.jpg',
        },
        spec: [
            'Lightly lined pad',
            'Memory fit lining',
            'Soft, elastic lace band',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [
                [
                    '32-34A',
                    '38B',
                ]
            ],
            online: [
                [
                    '32-34B-DDD',
                    '36C-DDD',
                ]
            ]
        }
    },

    // body by victoria
    {
        name: 'Demi',
        slug: 'bbv_demi',
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 2,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 2,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 11,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi/poster.jpg',
        },
        spec: [
            'Moderate-coverage cup',
            'Lightly lined',
            'Memory fit for extra support',
            'Padded straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-34A-DDD',
                    '36-38B-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '36A',
                    '40C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Perfect Coverage',
        slug: 'bbv_full_coverage',
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'perfect_coverage',
                label: 'Perfect Coverage',
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 3,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 12,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_coverage/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_coverage/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_coverage/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_coverage/poster.jpg',
        },
        spec: [
            'Full-Coverage Cup',
            'Lightly Lined',
            'Memory Fit for Extra Support',
            'Padded Straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [
                [
                    '32A',
                    '34A',
                ]
            ],
            online: [
                [
                    '30C-D',
                    '38G',
                    '40C-DDD,',
                ],
                [
                    '42D-DD',
                    '44C-D',
                ]
            ],
        }
    },
    {
        name: 'Push Up',
        slug: 'bbv_push_up',
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 4,
            },
        ],
        images: {
            front:'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/push_up/front.jpg',
            back:'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/push_up/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Push-up padding',
            'Memory fit for extra support',
            'Padded straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-38B-DDD',
                ]
            ],
            selectStores: [
                [
                    '32-34A',
                ]
            ],
            online: [
                [
                    '36A',
                    '40C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Perfect Shape',
        slug: 'bbv_perfect_shape',
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'perfect_shape',
                label: 'Perfect Shape',
                order: 1
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 13,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_shape/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_shape/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_shape/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_shape/poster.jpg',
        },
        spec: [
            'Full-coverage up',
            'Light push-up padding',
            'Memory fit for extra support',
            'Padded straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-38B-DDD',
                ]
            ],
            selectStores: [
                [
                    '32A',
                    '34A',
                ]
            ],
            online: [
                [
                    '40C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Strapless',
        slug: 'bbv_strapless',
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 4,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 5,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/strapless/poster.jpg',
        },
        spec: [
            'Full-coverage cup',
            'Lightly lined',
            'Memory fit for extra support',
            'Removable straps',
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-DDD',
                    '34-36B-DD',
                    '38C-DDD',
                ]
            ],
            selectStores: [
                [
                    '32A-B',
                    '34A',
                    '38B',
                ]
            ],
            online: [
                [
                    '40C-DD',
                ]
            ]
        }
    },
    {
        name: 'Maternity',
        slug: 'bbv_maternity',
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'wireless',
                label: 'WIRELESS',
                order: 2,
            },
            {
                slug: 'maternity',
                label: 'MATERNITY',
                order: 2,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/maternity/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/maternity/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/maternity/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/maternity/poster.jpg',
        },
        spec: [
            'Wireless frame',
            'Clip function at the cup',
            'Leak resistant and absorbent pad liner',
            'Fully adjustable padded straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '38B',
                    '40C-DD',
                ]
            ]
        }
    },

    //love cloud
    {
        name: 'Smooth Demi Bra',
        slug: 'lc_smooth_demi',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_demi/poster.jpg',

        },
        spec: [
            'Cloud-like padding',
            'Lightly lined',
            'Adjustable straps',
            'Side and back-smoothing Incredible technology',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '32-34A-DDD',
                    '36-38B-DDD',
                    '40C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Lace half-pad plunge bra',
        alternativeName: [
            'Lace half-pad',
            'plunge bra'
        ],
        slug: 'lc_lace_half_pad_plunge',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lace_half_pad_plunge/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lace_half_pad_plunge/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lace_half_pad_plunge/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lace_half_pad_plunge/poster.jpg',

        },
        spec: [
            'Cloud-like padding',
            '\u00BD cup coverage with light lining',
            'Adjustable straps',
            'Side and back-smoothing Incredible technology',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-D',
                    '34C-DDD',
                    '36C-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '32C-DDD',
                    '34B-DDD',
                    '36C-DD',
                    '38-40C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Push-up plunge bra',
        sizedProductName: [
            'Push-up plunge bra',
        ],
        alternativeName: [
            'Push-up',
            'plunge bra'
        ],
        slug: 'lc_push_up_plunge',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/push_up_plunge/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/push_up_plunge/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/push_up_plunge/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/push_up_plunge/poster.jpg',

        },
        spec: [
            'Cloud-like padding',
            'Adjustable straps',
            'Side and back-smoothing Incredible technology',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-D',
                    '34B-DD',
                    '36B-DDD',
                    '38B-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '32-34A-DDD',
                    '36-38B-DDD',
                    '40C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Front-close demi bra with lace back',
        sizedProductName: [
            'Front-close demi',
            'bra with lace back',
        ],
        alternativeName: [
            'Front-close',
            'demi bra with',
            'lace back'
        ],
        slug: 'lc_front_close_demi',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/front_close_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/front_close_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/front_close_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/front_close_demi/poster.jpg',

        },
        spec: [
            'Cloud-like uplift push-up',
            'Adjustable straps',
            'Lace side and back panels',
            'Front hook closure',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [
            ]
        }
    },
    {
        name: 'Smooth straight-neck lounge bra',
        sizedProductName: [
            'Smooth straight-',
            'neck lounge bra',
        ],
        alternativeName: [
            'Smooth',
            'straight-neck',
            'lounge bra'
        ],
        slug: 'lc_smooth_straight_neck_lounge',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_straight_neck_lounge/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_straight_neck_lounge/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_straight_neck_lounge/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_straight_neck_lounge/poster.jpg',

        },
        spec: [
            'Cloud-like padding',
            'Adjustable straps',
            'Side and back-smoothing Incredible technology',
            'Wireless silhouette',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    'XS',
                    'S',
                    'SDD',
                    'M',
                    'MDD',
                    'L',
                    'LDD',
                    'XL',
                ]
            ],
            abbreviated: [
                [
                    'XS',
                    'S',
                    'SDD',
                    'M',
                    'MDD',
                    'L',
                    'LDD',
                    'XL'
                ]
            ],
            selectStores: [],
            online: [
            ]
        }
    },
    {
        name: 'Sport bra',
        slug: 'lc_sport',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 5,
                showProductName: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/sport/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/sport/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/sport/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/sport/poster.jpg',

        },
        spec: [
            'Minimum support',
            'Ultra-stretchy, soft foam pad',
            'Wireless for 24-hour comfort',
            'Moisture-wicking fabric',
            'Fully adjustable straps'
        ],
        sizes: {
            primary: [
                [
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                ]
            ],
            abbreviated: [
                [
                    'XS-XL'
                ]
            ],
            selectStores: [],
            online: [
                [
                    'XS',
                    'S',
                    'SDD',
                    'M',
                    'MDD',
                    'L',
                    'XL',
                    'XXL'
                ]
            ]
        }
    },

    // incredible
    // {
    //     name: 'Demi',
    //     slug: 'inc_demi',
    //     collections: [
    //         {
    //             slug: 'incredible',
    //             label: 'INCREDIBLE',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'demi',
    //             label: 'DEMI',
    //             order: 5,
    //         },
    //         {
    //             slug: 'lightly_lined',
    //             label: 'LIGHTLY LINED',
    //             order: 8,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_demi/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_demi/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_demi/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_demi/poster.jpg',
    //     },
    //     spec: [
    //         'Moderate-coverage cup',
    //         'Lightly lined',
    //         'Cushioned underwire',
    //         'Smoothing back and band',
    //     ],
    //     sizes: {
    //     primary: [
    //     [
    //         'XS',
    //         'S',
    //         'SDD',
    //         'M',
    //         'MDD',
    //         'L',
    //         'LDD',
    //         'XL',
    //         '32A',
    //         '32B',
    //         '32C',
    //         '32D',
    //         '32DD (E)',
    //         '32DDD (F)',
    //         '34A',
    //         '34B',
    //         '34C',
    //         '34D',
    //         '34DD (E)',
    //         '34DDD (F)',
    //         '36B',
    //         '36C',
    //         '36D',
    //         '36DD (E)',
    //         '36DDD (F)',
    //         '38B',
    //         '38C',
    //         '38D',
    //         '38DD (E)',
    //         '38DDD (F)',
    //     ]
    // ],
    //         abbreviated: [
    //             [
    //             '32B-DD',
    //             '34B-DDD',
    //             '36B-DDD',
    //             '38B-DDD',
    //             ]
    //         ],
    //         selectStores: [
    //             [
    //             '32A',
    //             '34A',
    //             '32DDD',
    //             ]
    //         ],
    //         online: [
    //             [
    //                 '40C-DDD',
    //             ]
    //         ]
    //     }
    // },
    // {
    //     name: 'Perfect Shape',
    //     slug: 'inc_perfect_shape',
    //     collections: [
    //         {
    //             slug: 'incredible',
    //             label: 'INCREDIBLE',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'perfect_shape',
    //             label: 'Perfect Shape',
    //             order: 3
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/perfect_shape/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/perfect_shape/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/perfect_shape/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/perfect_shape/poster.jpg',
    //     },
    //     spec: [
    //         'Full-coverage cup',
    //         'Natural lift push-up',
    //         'Cushioned underwire',
    //         'Smoothing back and band',
    //     ],
    //     sizes: {
    //         abbreviated: [
    //             [
    //                 '32-36B-DDD',
    //                 '38C-DDD',
    //             ]
    //         ],
    //         selectStores: [
    //             [
    //                 '32A',
    //                 '34A',
    //                 '38B',
    //             ]
    //         ],
    //         online: [
    //             [
    //                 '40C-DDD',
    //             ]
    //         ]
    //     }
    // },

    // vs bare
    {
        name: 'Infinity Flex',
        slug: 'vsb_infinity_flex',
        collections: [
            {
                slug: 'vs_bare',
                label: 'VS BARE',
                isPrimary: true,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 14,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex/poster.jpg',
        },
        spec: [
            'Flexible adaptive fit technology',
            '4-way stretch adapts to your shape',
            'Gel wire for a flexible & comfortable lift',
            'Floating hideaway pad',
            'Smoothing wing & sleek back prevent bulging',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DD',
                    '34B-DD',
                    '36B-DDD',
                    '38B-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '32-38B-D3',
                    '38G',
                    '40DD-D3',
                    '42D-DD',
                    '44C-D',
                ]
            ]
        }
    },
    {
        name: 'Strapless Uplift',
        slug: 'vsb_strapless_uplift',
        collections: [
            {
                slug: 'vs_bare',
                label: 'SEXY ILLUSIONS',
                isPrimary: true,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 4,
                showProductName: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless_uplift/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless_uplift/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless_uplift/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless_uplift/poster.jpg',
        },
        spec: [
            'Push-up',
            'VS side smoothing solutions',
            'Exclusive non-slip cup and brand technology',
            'Removable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32A-DDD',
                    '34A-DDD',
                    '36B-DD',
                    '38C-DD',
                ]
            ],
            selectStores: [
                [
                    '36DDD',
                    '38B',
                ]
            ],
            online: []
        }
    },
    {
        name: 'Strapless',
        slug: 'vsb_strapless',
        collections: [
            {
                slug: 'vs_bare',
                label: 'SEXY ILLUSIONS',
                isPrimary: true,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 5,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 3,
                showProductName: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless/poster.jpg',
        },
        spec: [
            'Lightly lined',
            'VS side smoothing solutions',
            'Exclusive non-slip cup',
            'and band technology',
            'Removable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DDD',
                    '34B-DDD',
                    '36B-DD',
                    '38C-DD',
                ]
            ],
            selectStores: [
                [
                    '32-34A',
                    '36DDD',
                    '38B',
                ]
            ],
            online: []
        }
    },
    {
        name: 'Wireless Push-Up',
        slug: 'inc_wireless_push_up',
        collections: [
            {
                slug: 'vs_bare',
                label: 'INCREDIBLE',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 6,
            },
            {
                slug: 'wireless',
                label: 'WIRELESS',
                order: 4,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 15,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_wireless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_wireless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_wireless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_wireless/poster.jpg',
        },
        spec: [
            'Push-up padding',
            'Wireless silhouette',
            'Smoothing back and band',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-38B-DDD'
                ]
            ],
            selectStores: [],
            online: [
                [
                    '32A',
                    '34A',
                    '40C-DDD',
                ]
            ]
        }
    },

    // t-shirt
    {
        name: 'Demi',
        slug: 'ts_demi',
        collections: [
            {
                slug: 't_shirt',
                label: 'T-SHIRT BRA',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 3,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 6,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 6,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_demi/poster.jpg',
        },
        spec: [
            'Moderate-coverage cup',
            'Lightly lined',
            'Fully adjustable straps',
            'Can be worn classic or crossback',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-38B-DDD',
                ]
            ],
            selectStores: [
                [
                    '32A-C',
                    '34A',
                ]
            ],
            online: [
                [
                    '40C-DD',
                ]
            ]
        }
    },
    {
        name: 'Perfect Shape',
        slug: 'ts_perfect_shape',
        collections: [
            {
                slug: 't_shirt',
                label: 'T-SHIRT BRA',
                isPrimary: true,
            },
            {
                slug: 'perfect_shape',
                label: 'Perfect Shape',
                order: 2
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 8,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_perfect_shape/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_perfect_shape/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_perfect_shape/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_perfect_shape/poster.jpg',
        },
        spec: [
            'Full-Coverage Cup',
            'Natural Lift Push-Up',
            'Fully Adjustable Straps',
            'Can Be Worn Classic or Crossback',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-34B-DD',
                    '36-38B-DDD',
                ]
            ],
            selectStores: [
                [
                    '32A',
                    '34A',
                    '32-34DDD',
                ]
            ],
            online: [
                [
                    '40C-DD',
                ]
            ]
        }
    },
    {
        name: 'Wireless',
        slug: 'ts_wireless',
        collections: [
            {
                slug: 't_shirt',
                label: 'T-SHIRT BRA',
                isPrimary: true,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 7,
            },
            {
                slug: 'wireless',
                label: 'WIRELESS',
                order: 3,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 7,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_wireless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_wireless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_wireless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_wireless/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Lightly lined',
            'Fully adjustable straps',
            'Can be worn classic or crossback',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32-34A-DDD',
                    '36B-DDD',
                    '38C-DD',
                ]
            ],
            selectStores: [
                [
                    '38B',
                    '38DDD',
                ]
            ],
            online: [
                [
                    '38B',
                    '40C-D',
                ]
            ]
        }
    },

    // sexy tee
    {
        name: 'Demi',
        slug: 'st_demi',
        collections: [
            {
                slug: 'sexy_tee',
                label: 'SEXY TEE',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 4,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 8,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 10,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_demi/poster.jpg',
        },
        spec: [
            'Moderate-coverage cup',
            'Lightly lined',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-38B-DDD',
                ]
            ],
            selectStores: [
                [
                    '32A-C',
                    '34A',
                ]
            ],
            online: [
                [
                    '40C-DD',
                ]
            ]
        }
    },
    {
        name: 'Push Up',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/1117107554A2_OF_F.jpeg',
        slug: 'st_push_up',
        collections: [
            {
                slug: 'sexy_tee',
                label: 'SEXY TEE',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 5,
            },
            {
                slug: 'most_wanted',
                label: 'MOST WANTED',
                order: 9,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/poster.jpg',
        },
        spec: [
            'Soft push-up padding',
            'Plunge neckline',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32A-DD',
                    '34A-DDD',
                    '36B-DD',
                    '38C-DD',
                ]
            ],
            selectStores: [
                [
                    '32DDD',
                    '36DDD',
                    '38B',
                    '38DDD',
                ]
            ],
            online: [
                [
                    '40C-DD',
                ]
            ]
        }
    },

    // sport lounge
    {
        name: 'The Allegro',
        alternativeName: [
            'Medium Support',
            'Sport Bra'
        ],
        slug: 'sl_allegro',
        collections: [
            {
                slug: 'sport_lounge',
                label: 'THE ALLEGRO',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 1,
                showProductName: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/allegro/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/allegro/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/allegro/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/allegro/poster.jpg',
        },
        spec: [
            'Lightly lined',
            'Fully adjustable straps',
            'Pullover, wireless design',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32A-DD',
                    '34A-DD',
                    '36B-DD',
                    '38D-DD',
                ]
            ],
            selectStores: [],
            online: []
        }
    },
    {
        name: 'Knockout',
        alternativeName: [
            'Front-Close ',
            'Sport Bra'
        ],
        slug: 'sl_knockout',
        collections: [
            {
                slug: 'sport_lounge',
                label: 'KNOCKOUT',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 2,
                showProductName: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/knockout/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/knockout/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/knockout/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/knockout/poster.jpg',
        },
        spec: [
            'Maximum support',
            'Patented bra-within-a bra technology',
            'Structured-cups for compression',
            'Padded straps for stabilized support',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34B-DDD',
                    '36-38C-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '32B-C',
                    '36B',
                    '40D-DD',
                ]
            ]
        }
    },
    // {
    //     name: 'High-Neck',
    //     alternativeName: [
    //         'Essential High-neck',
    //         'Sport Bra',
    //     ],
    //     slug: 'sl_high_neck',
    //     collections: [
    //         {
    //             slug: 'sport_lounge',
    //             label: 'INCREDIBLE',
    //             isPrimary: true,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/high_neck/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/high_neck/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/high_neck/bra.jpg',
    //     },
    //     spec: [
    //         'Wireless',
    //         'Pullover style',
    //         'Removable pads',
    //     ],
    //     sizes: {
    //     primary: [
    //     [
    //         'XS',
    //         'S',
    //         'SDD',
    //         'M',
    //         'MDD',
    //         'L',
    //         'LDD',
    //         'XL',
    //         '32A',
    //         '32B',
    //         '32C',
    //         '32D',
    //         '32DD (E)',
    //         '32DDD (F)',
    //         '34A',
    //         '34B',
    //         '34C',
    //         '34D',
    //         '34DD (E)',
    //         '34DDD (F)',
    //         '36B',
    //         '36C',
    //         '36D',
    //         '36DD (E)',
    //         '36DDD (F)',
    //         '38B',
    //         '38C',
    //         '38D',
    //         '38DD (E)',
    //         '38DDD (F)',
    //     ]
    // ],
    //         abbreviated: [
    //             [
    //             'XS-XL',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: []
    //     }
    // },
    {
        name: 'Strappy Back',
        alternativeName: [
            'Essential',
            'Strappy Back',
        ],
        slug: 'sl_strappy_back',
        collections: [
            {
                slug: 'sport_lounge',
                label: 'INCREDIBLE',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 4,
                showProductName: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/strappy_back/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/strappy_back/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/strappy_back/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/strappy_back/poster.jpg',
        },
        spec: [
            'Minimum support',
            'Streamlined front',
            'Strappy back',
            'Bra pad inserts for shape',
        ],
        sizes: {
            primary: [
                [
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                ]
            ],
            abbreviated: [
                [
                    'XS-XL',
                ]
            ],
            selectStores: [],
            online: [
            ]
        }
    },
    {
        name: 'Max Sport',
        alternativeName: [
            'Max Sport Bra',
        ],
        slug: 'inc_max-sport',
        collections: [
            {
                slug: 'sport_lounge',
                label: 'INCREDIBLE',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 3,
                showProductName: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/max_sport/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/max_sport/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/max_sport/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/max_sport/poster.jpg',
        },
        spec: [
            'Maximum support',
            'Adjustable straps',
            'Ultra-flexible embedded wire',
            'Breathable wicking fabric',
            'Back hook-and-eye closure for adjustability',
        ],
        sizes: {
            primary: [
                [
                    '34B',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                '34B-DDD',
                '36C-DDD',
                '38D-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '32-36B-DDD',
                    '38C-DDD,',
                ],
                [
                    '40D-DD',
                ],
            ],
        }
    },




]

<template>
  <div class="relative h-full flex flex-col text-black bg-white">
      <router-view v-slot="{ Component, route}" class="bg-white">
          <transition :name="route.meta.transitionName" mode="out-in">
                <component :is="Component" />
          </transition>
      </router-view>
      <inactivity-notification @end="reset()" :exclude="['splash']"/>
  </div>
</template>

<script>

import InactivityNotification from "@/components/InactivityNotification";
export default {
    name: 'App',
    components: {InactivityNotification},

    methods: {
        reset() {
            this.$router.push({
                name: 'splash',
            });
        },
    }
}
</script>

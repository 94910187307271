<template>
    <app-layout>
        <div class="h-full w-full flex flex-col items-center px-14 pb-14 overflow-auto" ref="page">
            <div class="flex flex-col items-center flex-shrink-0">
                <h1 class="text-7.5xl leading-7.5xl font-didot">MOST WANTED BRAS</h1>
            </div>

            <div class="grid grid-cols-2 gap-x-7 gap-y-14 w-full font-benton mt-17 flex-shrink-0">
                <div class="slide-in flex-shrink-0"
                     v-for="(product, index) in products"
                     :key="product.name"
                     :style="{'animation-delay': (1+index)*0.1+'s'}"
                >
                    <router-link class="flex flex-col items-center"
                                 :to="
                                 {
                                     name: 'category.product',
                                     params: {
                                         category: 'most_wanted',
                                         product: product.slug,
                                     },
                                 }"
                    >
                        <img class="w-full" :src="product.images.poster" />
                        <div class="flex flex-col items-center justify-center font-benton mt-6">
                            <h2 class="font-benton-medium text-3.5xl leading-4xl text-center uppercase">{{collectionOverride[primaryCollection(product).label] ?? primaryCollection(product).label}}<br>{{product.name}}</h2>
                        </div>
                    </router-link>
                </div>
            </div>
            <button class="border-t border-navborder w-full mt-14 py-11 flex flex-col items-center justify-center flex-shrink-0"
                    @click="scrollToTop">
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.737 4.3024L2.18215 13.1332L0.440003 11.4455L10.6834 0.871699L21.56 11.4187L19.8714 13.16L10.737 4.3024Z" fill="black"/>
                </svg>
                <p class="mt-4 font-benton-medium text-2.5xl leading-7.1">Back to top</p>
            </button>
            <div class="w-full flex-shrink-0">
                <div class="border-t border-navborder flex items-center justify-center text-2.5xl leading-4xl pt-11">
                    <p>Need some assistance? <span class="font-benton-medium">Ask an associate for help.</span></p>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import Products from "@/router/products";
import AppLayout from "@/Layout/AppLayout";
import _ from "lodash";

export default {
    name: "MostWantedBrasPage",
    components: {AppLayout},
    data() {
        let products = _.filter(Products, (_product) => {
            return _.find(_product.collections, (_collection) => {
                return _collection.slug === 'most_wanted';
            });
        });

        return {
            products: _.orderBy(products, (_product) => {
                return _.find(_product.collections, (_collection) => {
                    return _collection.slug === 'most_wanted';
                }).order;
            }),

            collectionOverride: {
                'DREAM ANGELS WICKED': 'DREAM ANGELS',
            },
        }
    },

    methods: {
        primaryCollection(product) {
            if(product.collections.length === 1) {
                return product.collections[0];
            }
            return _.find(product.collections, (_collection) => {
                return _collection.isPrimary;
            });
        },

        scrollToTop() {
            this.$nextTick(() => {
                // this.$refs.contentPage.scrollTop = 0
                this.$refs.page.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        },
    },
}
</script>

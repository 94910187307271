<template>
    <div class="h-full w-full flex flex-col items-center pb-14 px-14 overflow-auto" ref="page">
        <div class="space-y-3 flex flex-col items-center flex-shrink-0">
            <h1 class="text-7.45xl leading-6.5xl font-benton-medium text-center uppercase">{{activeCollection.name}}</h1>
        </div>

        <div class="grid grid-cols-2 gap-x-7 gap-y-14 w-full font-benton mt-14 flex-shrink-0">
            <div class="slide-in flex-shrink-0"
                 v-for="(product, index) in products"
                 :key="product.name"
                 :style="{'animation-delay': (1+index)*0.1+'s'}"
            >
                <router-link class="flex flex-col items-center uppercase"
                             :to="
                             {
                                 name: 'product',
                                 params: {
                                     product: product.slug,
                                     collection: activeCollection.slug
                                 }
                             }"
                >
                    <img class="w-full flex-shrink-0" :src="product.images.poster" />
                    <h2 class="text-3.5xl font-benton-medium leading-5xl mt-6 flex-shrink-0">{{primaryCollection(product).label}}</h2>
                    <p class="font-benton text-2xl leading-9 flex-shrink-0"
                       v-if="activeCollection.showProductName || productActiveCollection(product).showProductName">
                        <template v-if="product.alternativeName">
                            <template v-for="name in product.alternativeName">
                                {{name}}
                            </template>
                        </template>
                        <template v-else>
                            {{product.name}}
                        </template>
                    </p>
                </router-link>
            </div>
        </div>
        <button class="border-t border-navborder w-full mt-14 py-11 flex flex-col items-center justify-center flex-shrink-0"
                v-if="activeCollection.scrollable"
                @click="scrollToTop">
            <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.737 4.3024L2.18215 13.1332L0.440003 11.4455L10.6834 0.871699L21.56 11.4187L19.8714 13.16L10.737 4.3024Z" fill="black"/>
            </svg>
            <p class="mt-4 font-benton-medium text-2.5xl leading-7.1">Back to top</p>
        </button>
        <div class="w-full mt-auto">
            <div class="border-t border-navborder flex items-center justify-center text-2.5xl leading-4xl pt-11 flex-shrink-0">
                <p>Need some assistance? <span class="font-bold">Ask an associate for help.</span></p>
            </div>
        </div>
    </div>
</template>

<script>
import Products from "@/router/products";
import Collections from "@/router/collections";
import _ from 'lodash';

export default {
    name: "ProductsPage",
    computed: {
        activeCollection() {
            return _.find(Collections, (_collection) => {
                return _collection.slug === this.collection;
            });
        },

        products() {
            let _products = _.filter(Products, (_product) => {
                return _.findIndex(_product.collections, (_collection) => {
                    return _collection.slug === this.collection;
                }) !== -1;
            });

            return _.orderBy(_products, (_product) => {
                return _.find(_product.collections, (_collection) => {
                    return _collection.slug === this.collection;
                }).order;
            });
        },

        tagline() {
            return this.activeCollection ? (this.activeCollection.description || null) : null;
        },
    },

    methods: {
        scrollToTop() {
            this.$nextTick(() => {
                // this.$refs.contentPage.scrollTop = 0
                this.$refs.page.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        },

        primaryCollection(product) {
            if(product.collections.length === 1) {
                return product.collections[0];
            }
            return _.find(product.collections, (_collection) => {
                return _collection.isPrimary;
            });
        },

        productActiveCollection(product) {
            return _.find(product.collections, (_collection) => {
                return _collection.slug === this.collection;
            });
        }
    },

    props: {
        collection: {
            type: String,
            required: true,
        },
        scrollable: {
            type: Boolean,
            default: true,
        },
    }
}
</script>

<template>
    <app-layout>
        <div class="h-full w-full flex flex-col items-center px-14 pb-14 overflow-auto" ref="page">
            <div class="flex flex-col items-center flex-shrink-0">
                <h1 class="text-7.5xl leading-7.5xl font-didot flex-shrink-0">CHOOSE CUP SIZE</h1>
                <p class="font-benton-regular text-2.5xl leading-4xl mt-6 flex-shrink-0">Additional sizes available online.</p>
            </div>

            <img class="w-full px-52 mt-16 slide-in" :src="cupSizes.poster"/>

            <div class="grid grid-cols-2 gap-x-7 gap-y-8 w-full px-32 font-benton mt-20">
                <div class="slide-in flex-shrink-0"
                     v-for="(cupSize, index) in cupSizes.cupSizes"
                     :key="cupSize"
                     :style="{'animation-delay': (1+index)*0.1+'s'}"
                >
                    <transition name="slide-up-fade">
                        <router-link class="bg-closegray flex flex-col items-center"
                                     :to="
                                     {
                                         name: 'cupSize',
                                         params: {
                                             cupSize: cupSize,
                                             size: curSize,
                                         },
                                     }"
                        >
                            <div class="flex flex-col items-center justify-center font-benton my-5 flex-shrink-0">
                                <h2 class="text-4xl font-benton-medium">{{cupSize}}</h2>
                            </div>
                        </router-link>
                    </transition>
                </div>
            </div>
            <div class="w-full px-14 mt-auto flex-shrink-0">
                <div class="border-t-2 border-navborder flex items-center justify-center text-2.5xl leading-4xl pt-11 flex-shrink-0">
                    <p>Need some assistance? <span class="font-bold">Ask an associate for help.</span></p>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import Sizes from "@/router/sizes.js";
import AppLayout from "@/Layout/AppLayout";
import _ from "lodash";

export default {
    name: "SizePage",
    components: {AppLayout},
    data() {
        return {
            size: _.filter(Sizes, (_size) => {
                return _size.slug === this.slug;
            }),
            cupSizes: _.find(Sizes, (_size) => _size.slug === this.$route.params.size),
            curSize: this.$route.params.size
        }
    },

    methods: {
        scrollToTop() {
            this.$nextTick(() => {
                // this.$refs.contentPage.scrollTop = 0
                this.$refs.page.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        }
    },
}
</script>
<template>
    <app-layout>
    <div class="h-full w-full flex flex-col items-center pb-14 px-14 overflow-auto" ref="page">
        <div class="flex flex-col items-center flex-shrink-0">
            <h1 class="text-7.5xl leading-7.5xl font-didot flex-shrink-0">AVAILABLE IN STORES</h1>
            <h2 class="text-3.5xl font-benton-medium mt-6">SIZE {{curCupSize}}</h2>
        </div>


        <div class="grid grid-cols-2 gap-x-7 gap-y-14 w-full font-benton mt-14 flex-shrink-0 mb-14">
            <div class="slide-in flex-shrink-0"
                 v-for="(product, index) in products"
                 :key="product.name"
                 :style="{'animation-delay': (1+index)*0.1+'s'}"
            >
                <router-link class="flex flex-col items-center"
                             :to="
                                 {
                                     name: 'sizedProduct',
                                     params: {
                                         size: curSize,
                                         cupSize: curCupSize,
                                         product: product.slug
                                     },
                                 }"
                >
                    <img class="w-full" :src="product.images.poster" />
                    <div class="text-2.5xl leading-4xl font-benton-regular flex-shrink-0 mt-6">{{primaryCollection(product).label}}</div>
                    <div class="flex flex-col items-center justify-center font-benton">
                        <h2 class="text-3.5xl font-benton-medium text-center uppercase" v-if="product.sizedProductName">
                            <p v-for="name in product.sizedProductName" :key="name">{{name}}</p>
                        </h2>
                        <h2 class="text-3.5xl font-benton-medium text-center uppercase" v-else-if="product.alternativeName">
                            <p v-for="name in product.alternativeName" :key="name">{{name}}</p>
                        </h2>
                        <h2 class="text-3.5xl font-benton-medium text-center uppercase" v-else>{{product.name}}</h2>
                    </div>
                </router-link>
            </div>
        </div>
            <button v-if="products.length > 5" class="border-t border-navborder w-full py-11 flex flex-col items-center justify-center flex-shrink-0"
                    @click="scrollToTop">
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.737 4.3024L2.18215 13.1332L0.440003 11.4455L10.6834 0.871699L21.56 11.4187L19.8714 13.16L10.737 4.3024Z" fill="black"/>
                </svg>
                <p class="mt-4 font-benton-medium text-2.5xl leading-7.1">Back to top</p>
            </button>
            <div class="w-full mt-auto">
                <div class="border-t border-navborder flex items-center justify-center text-2.5xl leading-4xl pt-11 flex-shrink-0">
                    <p>Need some assistance? <span class="font-bold">Ask an associate for help.</span></p>
                </div>
            </div>
        </div>
    
    </app-layout>
</template>

<script>
import Products from "@/router/products";
import AppLayout from "@/Layout/AppLayout";
import _ from 'lodash';


export default {
    name: "SizePage",
    components: {AppLayout},
    data() {
        return {
            products: _.filter(Products, (_product) => {
                return _.find(
                    _product.sizes.primary,
                    (_size) => _size.includes(this.$route.params.cupSize)
                );
            }),
            curCupSize: this.$route.params.cupSize,
            curSize: this.$route.params.size,
        }
    },

    methods: {
        scrollToTop() {
            this.$nextTick(() => {
                // this.$refs.contentPage.scrollTop = 0
                this.$refs.page.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        },

        primaryCollection(product) {
            if(product.collections.length === 1) {
                return product.collections[0];
            }
            return _.find(product.collections, (_collection) => {
                return _collection.isPrimary;
            });
        },
    },
}

</script>
